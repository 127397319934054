import React from 'react';
import IMAGE_RESIZE from "./Component/Image_Resize.js";

export default function App() {
  return (
    <>
      <IMAGE_RESIZE />
    </>
  );
}
